import { useCallback, useContext, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import { UserContext } from '../contexts/UserContext';
import { QUERY_LIST_CORPORATE_CLIENTS } from '../queries/DictionariesQueries';

export interface LocationState {
  corporateClients: [{ name: string; corporate_client_id: number }];
}

export const useCorporateClients = (
  defaultCorporateClients?: [
    { corporate_client_id: number; name: string; uuid: string },
  ],
) => {
  const { dbUser } = useContext(UserContext);
  const isSuperAdmin = !dbUser?.userCorporateClients?.length;

  const [request, { data: corpClients, ...rest }] = useLazyQuery(
    QUERY_LIST_CORPORATE_CLIENTS,
    {
      variables: {
        where: dbUser?.isCorporateAdmin
          ? {
              corporate_client_id: {
                in: dbUser?.userCorporateClients?.map(
                  (uc) => uc.corporate_client_id,
                ),
              },
            }
          : {},
      },
    },
  );

  const getCorporateClients = useCallback(() => {
    if (isSuperAdmin) {
      request();
    }
  }, [isSuperAdmin, request]);

  const corporateClients = useMemo(() => {
    if (isSuperAdmin) {
      return corpClients?.listCorporateClient;
    }

    return dbUser?.userCorporateClients?.map((corpClient) => ({
      corporate_client_id: corpClient?.corporate_client_id,
      name: corpClient?.corporateClient?.name,
      uuid: corpClient?.corporateClient?.uuid,
    }));
  }, [isSuperAdmin, corpClients, dbUser]);

  return {
    ...rest,
    corporateClients: corporateClients ?? defaultCorporateClients,
    getCorporateClients,
  };
};
