import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Checkbox } from 'antd';
import {
  NotificationEvents,
  NotificationFormState,
} from 'constants/Notifications';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

type NotificationEventsRecord = Record<NotificationEvents, any>;

const { Title } = Typography;

const UserPreferencesForm = ({ handleSubmit, data }: any) => {
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState<NotificationEventsRecord>(
    {} as NotificationEventsRecord,
  );

  useEffect(() => {
    if (!data?.prefs) return;
    setValues(() => {
      const dataPrefs = JSON.parse(data.prefs);
      const keys = Object.keys(NotificationFormState);
      return keys.reduce((acc, key) => {
        const prefs = dataPrefs[key];
        const newValues = [];
        if (prefs?.email) {
          newValues.push('email');
        }
        if (prefs?.in_app) {
          newValues.push('in_app');
        }
        return {
          ...acc,
          [key]: newValues,
        };
      }, {} as NotificationEventsRecord);
    });
  }, [data]);

  const onChange = (event: NotificationEvents) => {
    return async (checkedValues: CheckboxValueType[]) => {
      setLoading(true);
      await setValues((prevState) => {
        prevState[event] = checkedValues as string[];
        return prevState;
      });
      await onSubmit();
      setLoading(false);
    };
  };

  const onSubmit = async () => {
    const keys = Object.keys(values);
    const preparedValues = keys.reduce((acc, key) => {
      const event = key as NotificationEvents;
      const value = values[event];
      return {
        ...acc,
        [key]: {
          email: value.includes('email'),
          in_app: value.includes('in_app'),
        },
      };
    }, {});
    await handleSubmit(JSON.stringify(preparedValues));
  };

  return (
    <Row style={{ paddingTop: '20px', paddingBottom: '50px' }}>
      {Object.keys(NotificationFormState).map((key) => {
        const event = key as NotificationEvents;
        const group = NotificationFormState[event];
        return (
          <Col key={key} span={8}>
            <Row>
              <Col span={24}>
                <Title level={5}>{group.caption}</Title>
              </Col>
              <Col span={24}>
                <Checkbox.Group
                  style={{ width: '100%' }}
                  disabled={loading}
                  onChange={onChange(event)}
                  options={[
                    {
                      label: 'email',
                      value: 'email',
                      disabled: !group.email,
                    },
                    {
                      label: 'in app',
                      value: 'in_app',
                      disabled: !group.in_app,
                    },
                  ]}
                  value={values[event]}
                />
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default UserPreferencesForm;
