export enum FIELDS_NAMES {
  STATUS = 'Status',
  CONTAINER = 'Container',
  CORP_CLIENT = 'Corporate Client',
  CLIENT_INFO = 'Client Information',
  USER = 'User',
  MEMBERSHIP_LEVEL = 'User membership level',
  FROM_LOCATION = 'From Location',
  TO_LOCATION = 'To Location',
  ASSIGNED_ON = 'Assigned On',
  DUE_ON = 'Due On',
  RETURNED_ON = 'Returned On',
  PAYMENT_PROCESSOR = 'Payment Processor',
  TOTAL_FEES = 'Total Fees',
  CHARGED_LATE_FEES = 'Charged Late Fees',
  UNCHARGED_LATE_FEES = 'Uncharged Late Fees',
  REFUNDED_AMOUNT = 'Refunded Amount',
  ACTIONS = 'Actions',
  RETURNED_BY_USER = 'Returned by',
  NUMBER_OF_ASSIGNMENTS = 'Number of Checkouts',
  AMOUNT = 'Amount',
  ASSESSED_ON = 'Assessed On',
  PROCESSED_ON = 'Processed On',
  NOTE = 'Note',
  ASSIGNMENT_ID = 'Assignment ID',
  NAME = 'Name',
  EMAIL = 'Email',
  ADDED_ON = 'Added On',
  DATE = 'Date',
  TOTAL_AMOUNT = 'Total Amount',
  AMOUNT_CHARGED = 'Amount Charged',
  SUCCESS = 'Success',
  RESPONSE = 'Response',
  ROLE = 'Role',
  MAX_CONTAINERS_AT_A_TIME = 'Max Containers at a Time',
  MAX_CHECKOUTS_PER_DAY = 'Max Checkouts per Day',
  MAX_CONTAINERS_PER_MONTH = 'Max Containers per Month',
  RENT_DURATION_DAYS = 'Rent Duration Days',
  RENT_DURATION_MAX_DAYS = 'Rent Duration Max Days',
  WARNING_EMAIL_AMOUNT = 'Warning Email Amount',
  LOCKED_ACCOUNT_AMOUNT = 'Locked Account Amount',
}
