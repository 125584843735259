import React, { forwardRef, useContext } from 'react';
import { Select } from 'antd';
import { useQuery } from '@apollo/client';
import { QUERY_LIST_CORPORATE_CLIENTS } from 'queries/DictionariesQueries';
import { UserContext } from 'contexts/UserContext';

const { Option } = Select;

const CorporateClientSelect = forwardRef(
  ({ clients, ...props }: any, ref: any) => {
    const { dbUser } = useContext(UserContext);
    const { data, error } = useQuery(QUERY_LIST_CORPORATE_CLIENTS, {
      variables: {
        where: dbUser?.isCorporateAdmin
          ? {
              corporate_client_id: {
                in: dbUser?.userCorporateClients?.map(
                  (uc) => uc.corporate_client_id,
                ),
              },
            }
          : {},
      },
    });

    return (
      <Select ref={ref} style={{ width: '100%' }} {...props}>
        {data && !error
          ? (clients || data.listCorporateClient).map((l: any) => (
              <Option
                key={l.corporate_client_id}
                value={`${l.corporate_client_id}`}
              >
                {clients ? l.corporateClient.name : l.name}
              </Option>
            ))
          : null}
      </Select>
    );
  },
);

CorporateClientSelect.displayName = 'CorporateClientSelect';

export default CorporateClientSelect;
