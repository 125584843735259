import gql from 'graphql-tag';

export const QUERY_LIST_LOCATION_STATUSES = gql`
  query ListLocationStatuses {
    listLocationStatus {
      status_id
      name
    }
  }
`;
export const QUERY_LIST_ROLE = gql`
  query ListRole {
    listRole {
      role_id
      name
    }
  }
`;

export const QUERY_LIST_CAPABILITY = gql`
  query ListCapability {
    listCapability {
      capability_id
      name
    }
  }
`;

export const QUERY_LIST_PERMISSIONS = gql`
  {
    listPermission {
      permission_id
      name
    }
  }
`;

export const QUERY_LIST_CONTAINER_TYPES = gql`
  {
    listContainerType {
      type_id
      name
      icon
      corporate_client_id
      corporateClient {
        corporate_client_id
        name
      }
      sku
    }
  }
`;

export const QUERY_LIST_CORPORATE_CLIENTS = gql`
  query ListCorporateClient($where: WhereCorporateClientInput) {
    listCorporateClient(where: $where) {
      corporate_client_id
      name
      uuid
    }
  }
`;

export const QUERY_LIST_CORPORATE_CLIENTS_WITH_NAMING_CONV = gql`
  {
    listCorporateClient {
      corporate_client_id
      name

      namingConvention {
        nouns
        colors
        adjectives
        blacklist
      }
    }
  }
`;

export const QUERY_LIST_CORPORATE_CLIENT_STATUSES = gql`
  query ListCorporateClientStatuses {
    listCorporateClientStatus {
      status_id
      name
    }
  }
`;
