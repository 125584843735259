export enum NotificationEvents {
  CheckoutFinished = 'CheckoutFinished',
  ContainerReturned = 'ContainerReturned',
  ContainerDueToday = 'ContainerDueToday',
}

export const NotificationFormState = {
  [NotificationEvents.CheckoutFinished]: {
    caption: 'Checkout is finished',
    email: true,
    in_app: false,
  },
  [NotificationEvents.ContainerReturned]: {
    caption: 'Container is returned',
    email: true,
    in_app: true,
  },
  [NotificationEvents.ContainerDueToday]: {
    caption: 'Container due today',
    email: false,
    in_app: true,
  },
};
